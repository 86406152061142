import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import axios from "axios";
import api from "../../../API/api";
// import JoditEditor from "jodit-react";
import { Chip, Paper, Typography } from "@mui/material";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditorCustom from "../../../components/common/jodit-editor";
import { useCenterContext } from "../../../context/center-context";

const AddHealthPackage = (props) => {
  const { centers } = useCenterContext();
  const [description, setDescription] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  // for all department getting from api

  const [healthPackage, setHealthPackage] = useState({
    id: "",
    name: "",
    description: "",
    price: "",
    items: [],
    image: "",
    discountedPrice: "",
  });

  // this is for jodit editor

  useEffect(() => {
    console.log(healthPackage, "healthPackage");
  }, [healthPackage]);

  const handleDetails = (e) => {
    console.log(e, "e");
    setDescription(e);
  };

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      // since its an edit
      // we to modify the items array a bit
      const items = props.data.items.map((item) => item.name);
      setHealthPackage((prevState) => ({
        ...props.data,
        items: items,
        discountedPrice: props.data.discounted_price,
        center: props.data.centers[0].id,
      }));

      setDescription(props.data.description);
      setImagePreview(
        process.env.REACT_APP_BACKEND +
          "assets/health-packages/" +
          props.data.image
      );
    } else {
      setHealthPackage({});
    }

    // viewDepartment();
    console.log(props, "iSEdit");
  }, []);

  const handleInput = (e) => {
    setHealthPackage({
      ...healthPackage,
      [e.target.name]: e.target.value,
    });
  };

  const handleImage = (e) => {
    setImagePreview(e.target.value);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
      setHealthPackage((prevState) => ({
        ...prevState,
        image: file,
      }));
    };
  };

  const handleSubmit = () => {
    // return;
    if (healthPackage.name === null) {
      toast.error("health package name cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    if (healthPackage.price === null) {
      toast.error("price cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    if (description === null) {
      toast.error("description cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    if (healthPackage.items === null) {
      toast.error("health package items cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (healthPackage.image === null) {
      toast.error("image cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (healthPackage.center === null) {
      toast.error("center cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    const postData = new FormData();

    if (healthPackage.id) {
      postData.append("id", healthPackage.id);
    }
    postData.append("name", healthPackage.name);
    postData.append("price", healthPackage.price);

    if (healthPackage.discountedPrice) {
      postData.append("discounted_price", healthPackage.discountedPrice);
    }

    postData.append("description", description);
    postData.append("image", healthPackage.image);
    postData.append("center_id", healthPackage.center);

    if (healthPackage.items) {
      healthPackage.items.forEach((item, index) => {
        postData.append(`items[${index}]`, item);
      });
    } else {
      toast.error("health package items cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    api
      .addHealthPackage(postData)
      .then((res) => {
        console.log(res, "success");
        toast.success(`${res.data.message}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setHealthPackage({
          name: "",
          price: "",
          description: "",
        });
        if (props.handleClose) {
          props.handleClose();
        }
        // Swal.fire({
        //   title: "Success!",
        //   text: "Added Succeffully !",
        //   icon: "success",
        //   confirmButtonText: "Ok",
        // });
        // window.location.reload();
      })
      .catch((err) => {
        console.log(err.response, "dasbjd");
        if (err.response.data.errors) {
          toast.error(`${err.response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      });
  };

  const updateHealthPackage = () => {
    // for edit
    const postData2 = new FormData();
    if (healthPackage.id) {
      postData2.append("health_package_id", healthPackage.id);
    }
    if (healthPackage.name) {
      postData2.append("name", healthPackage.name);
    }
    if (healthPackage.price) {
      postData2.append("price", healthPackage.price);
    }
    if (healthPackage.description) {
      postData2.append("description", healthPackage.description);
    }

    if (healthPackage.discountedPrice) {
      postData2.append("discounted_price", healthPackage.discountedPrice);
    }

    if (healthPackage.image) {
      // check if props.data.image is the same as healthPackage.image
      if (props.data.image !== healthPackage.image) {
        postData2.append("image", healthPackage.image);
      }
    }

    if (healthPackage.items) {
      healthPackage.items.forEach((item, index) => {
        postData2.append(`items[${index}]`, item);
      });
    }

    if (healthPackage.center) {
      postData2.append("center_ids[0]", healthPackage.center);
    }
    // for edit

    // console.log(props.isEdit, 'props.isEdit')
    for (const value of postData2.values()) {
      console.log(value);
    }
    // return;

    api
      .updateHealthPackage(postData2)
      .then((res) => {
        console.log(res, "success");
        props.handleClose();
        Swal.fire({
          title: "Success!",
          text: "Added Succeffully !",
          icon: "success",
          confirmButtonText: "Ok",
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response, "dasbjd");
        if (err.response.data.errors) {
          toast.error(`${err.response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      });
  };

  return (
    <section>
      {/* <ToastContainer /> */}
      <h5 className="text-center">Add Health Package</h5>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="p- border row">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1 },
                }}
                noValidate
                autoComplete="off"
                className="row"
              >
                <div className="row">
                  <div className="col-md-6 my-2">
                    <TextField
                      id="standard-basic"
                      label="Health Package Name"
                      variant="outlined"
                      size="small"
                      className="form-control"
                      name="name"
                      onChange={handleInput}
                      value={healthPackage.name}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div className="col-md-6 my-2">
                    <TextField
                      id="standard-basic"
                      label={`Center, ${
                        healthPackage.center
                          ? centers.find(
                              (center) => center.id === healthPackage.center
                            ).name
                          : ""
                      }`}
                      variant="outlined"
                      size="small"
                      className="form-control"
                      name="center"
                      onChange={handleInput}
                      value={healthPackage.center}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      select
                      options={centers}
                    >
                      {centers.map((center) => (
                        <MenuItem value={center.id}>{center.name}</MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="col-md-6 my-2">
                    <TextField
                      type="number"
                      id="standard-basic"
                      label="Health Package Price"
                      variant="outlined"
                      size="small"
                      className="form-control"
                      name="price"
                      onChange={handleInput}
                      value={healthPackage.price}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-md-6 my-2">
                    <TextField
                      type="number"
                      id="standard-basic"
                      label="Discounted Price"
                      variant="outlined"
                      size="small"
                      className="form-control"
                      name="discountedPrice"
                      onChange={handleInput}
                      value={healthPackage.discountedPrice}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <Box
                      sx={{
                        border: 1,
                        borderColor: "grey.300",
                        borderRadius: 2,
                        p: 2,
                        mb: 2,
                        my: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Health Package Items(press enter to add after typing)
                      </Typography>
                      <Box sx={{ mb: 1, mt: 1 }}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          label="Add Health Package Item"
                          variant="outlined"
                          size="small"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const newItem = e.target.value.trim();
                              if (newItem) {
                                setHealthPackage((prevState) => ({
                                  ...prevState,
                                  items: [...(prevState.items || []), newItem],
                                }));
                                e.target.value = "";
                              }
                            }
                          }}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        {healthPackage.items &&
                          healthPackage.items.map((item, index) => (
                            <Chip
                              key={index}
                              label={item}
                              onDelete={() => {
                                setHealthPackage((prevState) => ({
                                  ...prevState,
                                  // whats happening here is that
                                  // on delete, the entire items array is again being created
                                  // but removing the current item from it
                                  // using index, i!== index
                                  // i would be the index of all item within the loop, index
                                  // is the current item index, so if i!== index, then only it will be added to the array
                                  items: prevState.items.filter(
                                    (_, i) => i !== index
                                  ),
                                }));
                              }}
                              sx={{ fontSize: "1rem", padding: "10px" }}
                            />
                          ))}
                      </Box>
                    </Box>
                  </div>

                  <label htmlFor="">Description</label>
                  <JoditEditorCustom
                    value={description}
                    onChange={handleDetails}
                  />

                  <Box
                    sx={{
                      position: "relative",
                      display: "block",
                      width: "100%",
                      my: 5,
                    }}
                  >
                    {/* <Box
                      sx={{
                        border: "2px dashed #ccc",
                        borderRadius: "4px",
                        padding: "20px",
                        textAlign: "center",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#f0f0f0",
                        },
                      }}
                      onClick={() =>
                        document.getElementById("image-upload").click()
                      }
                    >
                      {imagePreview ? (
                        <Box sx={{ position: "relative" }}>
                          <img
                            src={imagePreview}
                            alt="Preview"
                            style={{
                              width: "100%",
                              maxHeight: "400px",
                              objectFit: "contain",
                              borderRadius: "4px",
                            }}
                          />
                          <Button
                            variant="text"
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              setImagePreview("");
                              setHealthPackage((prevState) => ({
                                ...prevState,
                                image: "",
                              }));
                            }}
                            sx={{
                              position: "absolute",
                              top: 4,
                              right: 4,
                              minWidth: "30px",
                              minHeight: "30px",
                              padding: "4px",
                            }}
                          >
                            X
                          </Button>
                        </Box>
                      ) : (
                        <Typography>
                          Drag and drop an image here or click to upload
                        </Typography>
                      )}
                    </Box> */}
                    <input
                      type="file"
                      className="form-control"
                      name="cover"
                      id="imgInp"
                      onChange={(e) => {
                        e.stopPropagation();
                        setImagePreview(URL.createObjectURL(e.target.files[0]));
                        setHealthPackage((prevState) => ({
                          ...prevState,
                          image: e.target.files[0],
                        }));
                      }}
                    />
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        width: "100%",
                        maxHeight: "400px",
                        objectFit: "contain",
                        borderRadius: "4px",
                      }}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          handleFileUpload(file);
                        }
                      }}
                      style={{ display: "none" }}
                      id="image-upload"
                    />
                  </Box>
                </div>
                <div className="text-center my-2">
                  {props.isEdit ? (
                    <Button variant="contained" onClick={updateHealthPackage}>
                      Update
                    </Button>
                  ) : (
                    <Button variant="contained" onClick={handleSubmit}>
                      Submit
                    </Button>
                  )}
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddHealthPackage;
